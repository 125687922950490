@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://transroman.ebslab.in/inpage2unicode/fonts/nwn.ttf');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer: #031B34;
  --color-blog: #042c54;
  --color-text: #254d79;
  --color-info: #b3d7f5;
  --color-subtext: #FF8A71;
}

* {
  font-family: var(--font-family);
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
